import "./Home.css";
import Input from "../../components/Input/Input";
import { useState } from "react";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import TableRow from "../../components/Table/TableRow";
import TableColumn from "../../components/Table/TableColumn";

const Home = () => {
    const [inputOne, setInputOne] = useState("");
    const [inputTwo, setInputTwo] = useState("");
    const [inputThree, setInputThree] = useState("");
    const [tableValues, setTableValues] = useState([]);
    const [message, setMessage] = useState("");

    const handleCalculate = () => {
        if (!inputOne || !inputTwo || !inputThree) {
            return setMessage("Debe completar todos los campos numéricos.");
        }
        setMessage("");
        const aux = [];
        const final = [];

        aux.push(inputOne + inputTwo - inputThree);
        aux.push(inputThree + inputOne - inputTwo);
        aux.push(inputTwo + inputThree - inputOne);

        aux.forEach((item) => {
            final.push([item, item - 1, item - 2, item - 3, item - 4, item - 5, item - 6])
            final.push(["-", item + 1, item + 2, item + 3, item + 4, item + 5, item + 6])
        });
        setTableValues(final);
    }

    const validateInput = (value) => {
        return value >= 1 && value <= 16 || value === "";
    }

    return (
        <div className={"homeContainer"}>

            <p className={"title"}>SUEÑO</p>

            <div className={"inputContainer"}>
                <Input
                    validate={validateInput}
                    type={"number"}
                    value={inputOne}
                    onEnterKey={handleCalculate}
                    onChangeValue={setInputOne} />
                <Input
                    validate={validateInput}
                    type={"number"}
                    onEnterKey={handleCalculate}
                    value={inputTwo}
                    onChangeValue={setInputTwo} />
                <Input
                    validate={validateInput}
                    type={"number"}
                    onEnterKey={handleCalculate}
                    value={inputThree}
                    onChangeValue={setInputThree} />
            </div>

            {message && <p className={"message"}>{message}</p>}

            <div className={"inputContainer"}>
                <Button
                    onClick={() => {
                        setInputOne("");
                        setInputTwo("");
                        setInputThree("");
                        setTableValues([]);
                    }}
                    color={"danger"}>Borrar</Button>
                <Button onClick={handleCalculate}>Calcular</Button>
            </div>

            {tableValues.length ?
                <Table
                    headers={[0, 1, 2, 3, 4, 5, 6]}
                    items={tableValues}>
                    {(item, index) =>
                        <TableRow key={index}>
                            {item.map((column, index) =>
                                <TableColumn
                                    key={index}
                                    colSpan={1}>
                                    {column >= 0 ? column : ""}
                                </TableColumn>
                            )}
                        </TableRow>
                    }
                </Table>
                : ""
            }
        </div>
    );
}

export default Home;