import "./Button.css";

const Button = ({children, onClick, color = "primary"}) => {

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", margin: 0, padding: "10px 0"}}>
            <button onClick={onClick} className={`button ${color}`}>{children}</button>
        </div>
    );
}

export default Button;