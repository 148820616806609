import './assets/styles/main.css';
import Home from "./pages/home/Home";

function App() {
  return (
      <div className={"mainContainer"}>
        <Home/>
      </div>
  );
}

export default App;
