import "./Table.css";
const Table = ({headers = [], children, items}) => {
    return (
        <div className={"tableContainer"}>
        <table className={"table"}>
            <thead>
            <tr className={"tableHeader"}>
                {headers.map((header) => <th>{header}</th>)}
            </tr>
            </thead>
            <tbody>
                {items.map((item) => children(item))}
            </tbody>
        </table>
        </div>
    );
}

export default Table;